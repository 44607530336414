$primary-color: #df8b0c;
$grey-light: #8b8b8b;
$anchor-color: #278eff;
.main-content {
	overflow: auto;
	height: 100vh;
	padding-bottom: 6rem;
}

.show-progress-img-ct {
	position: absolute;
	top: 87px;
	left: 78px;
	width: 150px;
	.progress-text {
		position: absolute;
		right: -54px;
		top: 25px;
	}
}

.welcome-prog-bar {
	position: relative;
	top: 65px;
	margin: 0;
	width: 100%;
	.MuiLinearProgress-root {
		background-color: #fff;
	}
	.css-1bhivts {
		margin-right: 0px;
	}
	.box-progress-text-ct {
		position: absolute;
		top: 0px;
	}
}

.stepper-ct {
	.long-bar {
		height: 76px;
	}
	.box-item {
		height: 152px;
	}
	.first-box {
		&::before {
			content: '1';
			width: 42px;
			height: 28px;
			@apply bg-primary-500 absolute text-white;
			left: -42px;
			top: 62px;
			padding-left: 15px;
			padding-top: 2px;
		}
		&::after {
			content: '';
		}
	}
	.trianlge {
		position: absolute;
		right: -57px;
		width: 0;
		height: 0;
		border-top: 20px solid transparent;
		border-left: 40px solid $primary-color;
		border-bottom: 20px solid transparent;
		top: 60px;
		&:before {
			content: '';
			width: 26px;
			height: 18px;
			@apply bg-primary-500 absolute text-white;
			left: -56px;
			top: -9px;
		}
		.text-InTrainagle {
			left: -36px;
			@apply absolute text-white;
			top: -12px;
		}
	}
}

.bottom-section {
	button {
		&.MuiButtonBase-root {
			letter-spacing: 2.5px;
			@apply text-xl;
			font-weight: 200;
		}
	}
}

.regForm-ct {
	width: 500px;
	.social-login-btns-ct {
		button {
			text-align: left;
			white-space: nowrap;
		}
	}
	.sub-smallLabel,
	.terms-privacy-text {
		color: $grey-light;
	}
	.terms-privacy-sub-text {
		color: $anchor-color;
	}
}

.submit-btn-ct {
	position: absolute;
	bottom: 0;
	height: 100px;
	width: 128px;
	right: 33px;
	button {
		&.MuiButtonBase-root {
			@apply w-full h-full;
			font-weight: 300;
			padding-top: 35px;
		}
		.MuiButton-startIcon {
			position: absolute;
			top: 0px;
			left: 32px;
			span {
				font-size: 70px;
			}
		}
	}
}

.business-org-list-ct {
	.card-ct {
		button {
			&.MuiButtonBase-root {
				@apply font-light tracking-widest;
			}
		}
	}
}

.industryPage-left-ct {
	height: 300px;
	button {
		letter-spacing: 2px;
	}
}

.org-name-page-ct {
	.org-name-typo-bar-ct {
		img {
			position: absolute;
			right: 32px;
			top: 7px;
		}
		.typo-label {
			right: 0px;
			top: 10px;
		}
	}
}

.feature-selection-ct {
	.checkbox-group-ct {
		.MuiFormControlLabel-root {
			.MuiCheckbox-root {
				svg {
					@apply text-white;
				}
			}
		}
	}
}

.payment-page-ct {
	.left-page-ct {
		.rccs-cc-ct {
			.rccs {
				margin: initial;
			}
		}
		.MuiInputBase-formControl {
			@apply bg-white;
			.MuiInputBase-input {
				@apply uppercase text-base xl:text-xl;
			}
		}
	}
	.submit-btn-ct {
		top: 48px;
		right: 0;
	}
}

@media screen and (max-width: 1100px) and (min-width: 1024px) {
	.stepper-ct {
		.box-item {
			width: 17rem;
		}
	}
}

@media screen and (max-width: 1100px) {
	.show-progress-img-ct {
		width: 110px;
		.progress-text {
			top: 14px;
		}
	}
	.bottom-section {
		button {
			&.MuiButtonBase-root {
				width: 190px;
				height: 55px;
			}
		}
	}
}

@media only screen and (max-width: 1023px) {
	.stepper-ct {
		.first-box {
			&::before {
				width: 28px;
				height: 44px;
				left: 162px;
				top: -44px;
				padding-left: 11px;
				padding-top: 10px;
			}
		}
		.trianlge {
			right: 190px;
			top: 168px;
			transform: rotate(90deg);
		}
		.text-InTrainagle {
			left: -31px;
			top: -13px;
			transform: rotate(268deg);
		}
	}
}

@media screen and (max-width: 768px) {
	.stepper-ct {
		.box-item {
			height: auto;
		}
		.trianlge {
			display: none;
		}
		.first-box {
			&::before {
				display: none;
			}
		}
	}

	.submit-btn-ct {
		position: unset;
		width: 100%;
		height: auto;
		padding-top: 8px;
		font-weight: bold;
		button {
                &.MuiButtonBase-root {
                    padding-top: 8px;
                    font-weight: bold;
                }
            }
	}

	.industryPage-left-ct {
		height: 200px;
	}

	.bottom-section {
		button {
			&.MuiButtonBase-root {
				width: 140px;
				height: 55px;
			}
		}
	}
}
