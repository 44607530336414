@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?7a5b47a82b7a9c922b7418b4915f6750") format("truetype"),
url("./flaticon.woff?7a5b47a82b7a9c922b7418b4915f6750") format("woff"),
url("./flaticon.woff2?7a5b47a82b7a9c922b7418b4915f6750") format("woff2"),
url("./flaticon.eot?7a5b47a82b7a9c922b7418b4915f6750#iefix") format("embedded-opentype"),
url("./flaticon.svg?7a5b47a82b7a9c922b7418b4915f6750#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-plus:before {
    content: "\f101";
}
.flaticon-add:before {
    content: "\f102";
}
.flaticon-add-user:before {
    content: "\f103";
}
.flaticon-add-group:before {
    content: "\f104";
}
.flaticon-dashboard:before {
    content: "\f105";
}
.flaticon-up:before {
    content: "\f106";
}
.flaticon-downward-arrow:before {
    content: "\f107";
}
.flaticon-right-arrow:before {
    content: "\f108";
}
.flaticon-left-arrow:before {
    content: "\f109";
}
.flaticon-upward-arrow:before {
    content: "\f10a";
}
.flaticon-maximize:before {
    content: "\f10b";
}
.flaticon-website:before {
    content: "\f10c";
}
.flaticon-marketing:before {
    content: "\f10d";
}
.flaticon-satisfaction:before {
    content: "\f10e";
}
.flaticon-recruitment:before {
    content: "\f10f";
}
.flaticon-schedule:before {
    content: "\f110";
}
.flaticon-search:before {
    content: "\f111";
}
.flaticon-view:before {
    content: "\f112";
}
.flaticon-hidden:before {
    content: "\f113";
}
.flaticon-mail:before {
    content: "\f114";
}
.flaticon-trash-bin:before {
    content: "\f115";
}
.flaticon-refresh:before {
    content: "\f116";
}
.flaticon-menu:before {
    content: "\f117";
}
.flaticon-user-1:before {
    content: "\f118";
}
.flaticon-brand-image:before {
    content: "\f119";
}
.flaticon-public-relation:before {
    content: "\f11a";
}
.flaticon-folder:before {
    content: "\f11b";
}
.flaticon-cogwheel:before {
    content: "\f11c";
}
.flaticon-people:before {
    content: "\f11d";
}
.flaticon-analysis:before {
    content: "\f11e";
}
.flaticon-drag:before {
    content: "\f11f";
}
.flaticon-store:before {
    content: "\f120";
}
.flaticon-buyer:before {
    content: "\f121";
}
.flaticon-lead:before {
    content: "\f122";
}
.flaticon-call:before {
    content: "\f123";
}
.flaticon-upload:before {
    content: "\f124";
}
.flaticon-skyline:before {
    content: "\f125";
}
.flaticon-building:before {
    content: "\f126";
}
.flaticon-office-building:before {
    content: "\f127";
}
.flaticon-building-1:before {
    content: "\f128";
}
.flaticon-repair-shop:before {
    content: "\f129";
}
.flaticon-location:before {
    content: "\f12a";
}
.flaticon-location-2:before {
    content: "\f12b";
}
.flaticon-location-1:before {
    content: "\f12c";
}
.flaticon-address:before {
    content: "\f12d";
}
