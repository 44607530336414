.sub-menu-block {
	background-color: #fff;
	.child-menu-block {
		position: absolute;
		z-index: 14;
		left: 12.1rem;
		width: 100%;
		margin-top: -28px;
	}
}
@media  screen and (min-width: 768px) {
	.hoverMe {
		position: relative;
	
		&:after {
			content: attr(data-after-content);
			position: fixed;
			left: 4rem;
			transition: left 2s ease;
			-webkit-transition: left 2s ease;
			color: #565656;
			z-index: 8;
		}
	}
}


.isFixed {
	left: 0;
	right: 0;
	width: 100%;
	z-index: 9;
	position: fixed;
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
    .mobile-screen {
		padding-bottom: 6rem !important;
	}
}
