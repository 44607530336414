@import url('../assets/fonts/flaticon.css');

$primary: #3a93b4;
$secondary: #f2b558;
$attention: #d08360;

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;

@import url('https://fonts.googleapis.com/css?family=Noto+Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Icons+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

body {
	font-family: 'Noto Sans';
	width: 100%;
	height: 100%;
	@apply bg-gray-lightest;
}

.logo {
	max-width: 75px;
	margin: 25px 0 0 55px;
}

.navitems span {
	color: $attention;
	filter: drop-shadow(0 0 0.5rem rgb(255, 255, 255));
}

.activenav {
	background: linear-gradient(
		90deg,
		rgba(208, 131, 96, 1) 17%,
		rgba(242, 181, 88, 1) 100%
	);
}

.dotted-divider {
	// width: 83.333333%;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.dotted-divider {
	&::before,
	&::after {
		content: '';
		width: 40%;
		// max-width: 400px;
		height: 15px;
		display: inline-block;
		background: radial-gradient(ellipse, #bdbdbd 18%, rgba(255, 255, 255, 0) 0%);
		background-size: 25px 25px;
		background-repeat: repeat-x;
		background-position: left center;
		margin: 0 2% 0 -55%;
	}

	&::after {
		margin: 0 -55% 0 2%;
		background-position: right center;
	}
}

.dotted-bottom {
	&::after {
		content: '';
		vertical-align: middle;
		display: inline-block;
		width: 100%;
		border-bottom: 3px dotted #ccc;
		margin-top: -20px;
		// margin: 0 -55% 0 2%;
	}
}

@media screen and (max-width: 640px) {
	.ag-paging-row-summary-panel {
		display: none;
	}
	.dotted-divider {
		&::before,
		&::after {
			content: unset;
		}
	}
}

.tooltip {
	@apply invisible absolute;
}

.has-tooltip:hover .tooltip {
	@apply visible z-50;
}

.square {
	width: 10rem;
	height: 10rem;
}

// =====================
// Splash Gradient
// =====================

.splash {
	margin: 50px 0 0 0;
}

.splash div {
	padding: 0 3% 0 3%;
}

.splash p {
	margin-top: -40px;
}

.splash img {
	border-radius: 25px;
}

.splash-1 {
	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		width: 70vw;
		z-index: -11;
		height: 50vh;
		left: 8vw;
		top: 30vh;
		background: rgba(58, 147, 180, 0.14);
		background: -moz-linear-gradient(
			76.43deg,
			rgba(58, 147, 180, 0.14) 19.64%,
			rgba(78, 151, 170, 0.4) 27.29%,
			rgba(142, 163, 138, 0.34) 51.53%,
			$secondary 77.89%,
			#d08360 101.28%
		);
		background: -webkit-linear-gradient(
			76.43deg,
			rgba(58, 147, 180, 0.14) 19.64%,
			rgba(78, 151, 170, 0.4) 27.29%,
			rgba(142, 163, 138, 0.34) 51.53%,
			$secondary 77.89%,
			#d08360 101.28%
		);
		background: linear-gradient(
			76.43deg,
			rgba(58, 147, 180, 0.14) 19.64%,
			rgba(78, 151, 170, 0.4) 27.29%,
			rgba(142, 163, 138, 0.34) 51.53%,
			$secondary 77.89%,
			#d08360 101.28%
		);
		transform: rotate(170deg);
		opacity: 0;
		transition: all 1s ease-out;
		@apply filter blur-3xl;
	}
}

.splash-1 {
	&:hover::before {
		opacity: 1;
		transition: all 1s ease;
	}
}

.splash-2 {
	&::before {
		left: 8vw;
		top: 30vh;
		content: '';
		display: inline-block;
		position: absolute;
		z-index: -11;
		height: 50vh;
		width: 70vw;
		background: rgba(58, 147, 180, 0.1);
		background: -moz-linear-gradient(
			76.43deg,
			rgba(58, 147, 180, 0.1) 19.64%,
			rgba(82, 151, 168, 0.35) 33.25%,
			#7c9f93 54.08%,
			rgba(242, 181, 88, 0.61) 81.72%,
			rgba(208, 131, 96, 0.25) 101.28%
		);
		background: -webkit-linear-gradient(
			76.43deg,
			rgba(58, 147, 180, 0.1) 19.64%,
			rgba(82, 151, 168, 0.35) 33.25%,
			#7c9f93 54.08%,
			rgba(242, 181, 88, 0.61) 81.72%,
			rgba(208, 131, 96, 0.25) 101.28%
		);
		background: linear-gradient(
			76.43deg,
			rgba(58, 147, 180, 0.1) 19.64%,
			rgba(82, 151, 168, 0.35) 33.25%,
			#7c9f93 54.08%,
			rgba(242, 181, 88, 0.61) 81.72%,
			rgba(208, 131, 96, 0.25) 101.28%
		);
		transform: rotate(170deg);
		opacity: 0;
		transition: all 1s ease-out;
		@apply filter blur-3xl;
	}
}

.splash-2 {
	&:hover::before {
		opacity: 1;
		transition: all 1s ease;
	}
}

.splash-3 {
	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		width: 70vw;
		z-index: -11;
		height: 50vh;
		left: 8vw;
		top: 30vh;
		background: rgba(58, 147, 180, 0.5);
		background: -moz-linear-gradient(
			78.24deg,
			rgba(58, 147, 180, 0.5) 11.76%,
			#83a090 35.68%,
			rgba(242, 181, 88, 0.54) 67.15%,
			rgba(218, 146, 94, 0.25) 81.08%,
			$attention 101.58%
		);
		background: -webkit-linear-gradient(
			78.24deg,
			rgba(58, 147, 180, 0.5) 11.76%,
			#83a090 35.68%,
			rgba(242, 181, 88, 0.54) 67.15%,
			rgba(218, 146, 94, 0.25) 81.08%,
			$attention 101.58%
		);
		background: linear-gradient(
			78.24deg,
			rgba(58, 147, 180, 0.5) 11.76%,
			#83a090 35.68%,
			rgba(242, 181, 88, 0.54) 67.15%,
			rgba(218, 146, 94, 0.25) 81.08%,
			$attention 101.58%
		);
		transform: rotate(170deg);
		opacity: 0;
		transition: all 1s ease-out;
		@apply filter blur-3xl;
	}
}

.splash-3 {
	&:hover::before {
		opacity: 1;
		transition: all 1s ease;
	}
}

.splash-4 {
	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		width: 70vw;
		z-index: -11;
		height: 50vh;
		left: 8vw;
		top: 30vh;
		background: rgba(242, 181, 88, 0.28);
		background: -moz-linear-gradient(
			76.43deg,
			$primary 19.64%,
			rgba(242, 181, 88, 0.28) 68.11%,
			rgba(228, 160, 91, 0.08) 81.72%,
			rgba(208, 131, 96, 0.53) 101.28%
		);
		background: -webkit-linear-gradient(
			76.43deg,
			$primary 19.64%,
			rgba(242, 181, 88, 0.28) 68.11%,
			rgba(228, 160, 91, 0.08) 81.72%,
			rgba(208, 131, 96, 0.53) 101.28%
		);
		background: linear-gradient(
			76.43deg,
			$primary 19.64%,
			rgba(242, 181, 88, 0.28) 68.11%,
			rgba(228, 160, 91, 0.08) 81.72%,
			rgba(208, 131, 96, 0.53) 101.28%
		);
		transform: rotate(170deg);
		opacity: 0;
		transition: all 1s ease-out;
		@apply filter blur-3xl;
	}
}

.splash-4 {
	&:hover::before {
		opacity: 1;
		transition: all 1s ease;
	}
}

// ===========
// SMS & Voice
// ===========

.phone {
	margin: 0 20px 0 0;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	.MuiTreeView-root {
		overflow-y: unset !important;
	}
}

.phone-bar {
	height: 7px;
	width: 30%;
	background-color: #8d8a8a;
	margin-left: 35%;
	margin-right: 35%;
	margin-bottom: 20px;
	bottom: 0;
	position: absolute;
	border-radius: 3px;
}

.our-message {
	position: relative;
	.message-content {
		background-color: #ddd;
		border-radius: 20px 20px 20px 0;
		padding: 5px;
	}

	// &::before
	//     content: "";
	//     bottom: -17px;
	//     left: -36px;
	//     position: absolute;
	//     border: 0 solid;
	//     display: block;
	//     width: 43px;
	//     height: 27px;
	//     background-color: transparent;
	//     border-bottom-left-radius: 70%;
	//     border-bottom-right-radius: 54%;
	//     box-shadow: -25px 1px 0 -6px #ddd;
	//     -webkit-transform: rotate(1deg);
	//     transform: rotate(1deg);
	//     -webkit-transform: rotateY(180deg);
	//     transform: rotateY(180deg);
	//     z-index: 999;
}

.their-response {
	// background-color: #5398a8;
	position: relative;
	.message-content {
		background-color: #147efb;
		border-radius: 20px 20px 0 20px;
		padding: 5px;
		text-align: right;
		color: #fff;
	}

	// &::before
	//     content: ""
	//     bottom: -14px
	//     right: -31px
	//     position: absolute
	//     border: 0 solid
	//     display: block
	//     width: 43px
	//     height: 27px
	//     background-color: transparent
	//     border-bottom-left-radius: 70%
	//     border-bottom-right-radius: 54%
	//     box-shadow: -25px 1px 0 -6px #5398a8
	//     -webkit-transform: rotate(1deg)
	//     transform: rotate(1deg)
	//     -webkit-transform: rotateY(357deg)
	//     transform: rotateY(357deg)
	//     z-index: 999
}

.their-response-list {
	padding: 10px;
	text-align: right;
	background-color: #5398a8;
	margin-top: 10px;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	position: relative;
}

.multiple-messages {
	margin-right: -50px;
	margin-left: 50px;
	padding: 10px;
	text-align: right;
	background-color: #5398a8;
	margin-top: 10px;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	position: relative;
	box-shadow: 0 1px 1px #498d9c,
		0 12px 0 -5px rgba(83, 152, 168, 0.6196078431372549),
		0 20px 0 -10px rgba(97, 172, 189, 0.14901960784313725),
		0 22px 1px -9px rgba(83, 152, 168, 0.34901960784313724); // 0 1px 1px #498d9c, 0 12px 0 -5px rgb(83, 152, 168 / 62%), 0 20px 0 -10px rgb(97, 172, 189 / 15%), 0 22px 1px -9px rgb(83, 152, 168 / 35%);
}

.gray-shadow {
	box-shadow: 0 0 20px 5px theme('colors.gray.200');
}

.ag-input-field-input .ag-checkbox-input {
	transform: translate(0, -10px);
}

// =====================
// AG Grid Customization
// =====================

// .existing .ag-paging-panel
//     display: none

// .ag-paging-row-summary-panel-number, .ag-paging-number
//     color: $secondary

// .ag-theme-alpine .ag-root-wrapper
//     border: 0

// .ag-theme-alpine .ag-paging-panel
//     border-top: 0

// .ag-theme-alpine .ag-row
//     border-width: 0

// .ag-row
//     border-right: 0
//     border-left: 0

// .ag-floating-filter-input input
//     background-color: red

// .aggrid-custom-pagination
//     position: absolute
//     bottom: 12px
//     margin: 0 18px

// .ag-selection-checkbox {
//     width: 0px;
//     margin-right: 0px !important;
// }

// .ag-theme-alpine .ag-header-label-icon .ag-icon-filter::before, .ag-theme-alpine .ag-ltr .ag-filter-toolpanel-group.ag-has-filter > .ag-group-title-bar .ag-group-title::after
//     @apply text-red-500;

// .ag-theme-alpine .ag-header-cell[col-id="CustomerID"] .ag-header-cell-resize::after, .ag-theme-alpine .ag-header-cell[col-id="0"] .ag-header-cell-resize::after
//     content: unset;

// .ag-cell-value
//     color: #565656

// .ag-body-horizontal-scroll
//     display: none !important

// .header-center *
//     justify-content: center !important;
//     z-index: 0 !important;

// .actions-cell *
//     z-index: 0 !important;

// @media screen and (max-width: 640px)
//     .ag-pinned-right-cols-container , .ag-pinned-right-header
//         min-width: 150px !important;

// .ag-cell-first-right-pinned
//     border-left: none !important;

// .ag-theme-alpine .ag-root-wrapper
//     margin: 0 0;

// .pagination {display: flex;}

.page-item {
	margin: 10px;
}

.loader {
	border-top-color: #3498db;
	animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background-color: #dcdcdc;
}

::-webkit-scrollbar-thumb {
	background-color: #989898;
	height: 15px;
	border-radius: 8px;
}

.active-inner-shadow {
	box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.3);
}

.box-inner-shadow {
	&:hover {
		box-shadow: inset 0 3px 7px 0 rgba(0, 0, 0, 0.3);
	}
}

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }

// input[type=number] {
//     -moz-appearance: textfield;
// }

.text-vertical-align {
	writing-mode: vertical-rl;
	text-orientation: mixed;
}

.bg-blank-gradient {
	background-image: linear-gradient(45deg, #f7f7f7 25%, transparent 25%),
		linear-gradient(-45deg, #f7f7f7 25%, transparent 25%),
		linear-gradient(45deg, transparent 75%, #f7f7f7 75%),
		linear-gradient(-45deg, transparent 75%, #f7f7f7 75%);
	background-size: 20px 20px;
	background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px;
}

// .material-icons-outlined
//     font-size: inherit

// General Fix For React Select Border Radius
.css-1s2u09g-control {
	border-radius: 9999px !important;
}

.css-1pahdxg-control:hover {
	border-color: transparent !important;
}

.drop-down__input {
	width: 100px !important;
	border: none !important;
	border-color: white !important;
}

.MuiButton-root.MuiButton-contained {
	@apply text-white font-semibold;
}

.ellipse-after-two-lines {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fullscreenwithheader {
	height: calc(100vh - 80px);
}

.social-block {
	height: calc(100vh - 270px);
}

.fb-specific {
	.MuiButton-outlinedInherit {
		color: #1877f2;
	}
}

.twitter-specific {
	.MuiButton-outlinedInherit {
		color: #1da1f2;
	}
}

.insta-specific {
	.MuiButton-outlinedInherit {
		color: #8a3ab9;
	}
}

.linkedin-specific {
	.MuiButton-outlinedInherit {
		color: #0072b1;
	}
}

.google-specific {
	.MuiButton-outlinedInherit {
		color: #4285f4;
	}
}

// Temporary solution to remove iframe on development
body {
	> iframe {
		display: none !important;
	}
}
